import { Box } from "@mui/material";
import { mergeSx } from "~/helpers";
import { SVGBoxProps } from "./SVGBoxProps";
import { SVGAttributes } from "react";

export const Logo = ({
  color,
  outlineColor,
  sx,
  svgProps,
  ...boxProps
}: SVGBoxProps & { outlineColor?: SVGAttributes<"path">["fill"] }) => {
  const color1 = color ? "currentColor" : "#6d88f4";
  const color2 = color ? "currentColor" : "#263a96";
  return (
    <Box
      component="span"
      sx={mergeSx(
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        sx
      )}
      color={color}
      {...boxProps}
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 379.48"
      >
        <path
          fill={outlineColor || "none"}
          d="M880.18,166.31c-21.67,0-40.55,8.3-53.65,21.95-12.03-13.75-29.31-21.95-49.46-21.95-12.51,0-24.34,3.72-34.39,10.45-10.37-6.76-22.7-10.45-36.06-10.45-6.02,0-11.9.85-17.5,2.49h-71.3c-6.57-1.59-13.93-2.49-22.19-2.49-16.08,0-29.29,3.71-39.54,9.71-3.91-7.91-9.16-14.9-15.5-20.7,10.05-11.3,16.16-26.13,16.16-42.36,0-35.32-28.91-64.05-64.44-64.05-28.78,0-54.28,19.27-62.03,46.84-1.6,5.68-2.41,11.47-2.41,17.21s.81,11.51,2.41,17.2c2.68,9.52,7.5,18.02,13.82,25.11-2.92,2.66-5.61,5.58-8.04,8.72-1.18,1.52-2.21,3.15-3.19,4.82h-218.89c-6.39-1.61-13.2-2.49-20.35-2.49-17.75,0-33.63,5.57-45.99,15.09-2.56-2.35-5.36-4.44-8.38-6.19v-25.02h-46.58l-46.41,46.15v46.66h17.03c1.65,39.1,30.13,64.14,73.5,64.14h20.15c3.45,0,6.24-2.79,6.24-6.24,9.22,4,19.51,6.24,30.45,6.24,21.74,0,40.68-8.28,53.8-21.9,12.27,13.72,29.87,21.9,50.38,21.9,3.68,0,7.32-.31,10.86-.91,5.41-.91,10.85-1.64,16.33-1.71l53.52-.72c12.06-2.2,22.58-8.78,29.93-17.99v.04c3.39-3.8,5.81-8.49,7.58-13.26.08-.22.16-.44.24-.66,4.33-12.14,4.39-24.64,4.39-24.64v15.93c0,41.95,30.8,73.58,71.64,73.58,28.79,0,52.58-15.73,64.11-39.71,11.37,6.41,25.53,10.05,41.52,10.05,17.17,0,34.44-4.89,47.07-14.41,8.68,7.41,19.91,11.92,32.19,11.92h161.03c3.46,0,6.27-2.81,6.27-6.27,10.49,5.59,22.63,8.76,35.68,8.76,41.56,0,72.9-30.2,72.9-70.25s-30.66-70.59-72.9-70.59Z"
        />
        <path
          fill={color1}
          d="M492.3,150.63c20.98,0,38.05-16.9,38.05-37.67s-17.07-37.67-38.05-37.67c-17.46,0-32.17,11.71-36.63,27.6-.9,3.21-1.42,6.57-1.42,10.06h0c0,3.49.52,6.86,1.42,10.07,4.46,15.89,19.18,27.6,36.63,27.6ZM492.3,94.06c10.53,0,19.09,8.48,19.09,18.89s-8.56,18.9-19.09,18.9-19.09-8.48-19.09-18.9,8.56-18.89,19.09-18.89Z"
        />
        <path
          fill={color2}
          d="M492.3,163.4c-26.93,0-45.25,20.41-45.25,47.2v52.62c0,26.79,18.88,47.2,45.25,47.2s45.25-20.41,45.25-47.2v-52.62c0-26.79-18.88-47.2-45.25-47.2ZM518.12,229.05v34.18c0,16.94-10.69,28.6-25.82,28.6s-25.82-12.08-25.82-28.6v-52.62c0-16.94,10.69-28.6,25.82-28.6s25.82,12.08,25.82,28.6v18.45Z"
        />
        <path
          fill={color2}
          d="M507.43,207.84c0-8.36-6.78-15.13-15.13-15.13s-15.13,6.78-15.13,15.13c0,6.47,4.07,11.94,9.78,14.11v30.03c-5.71,2.17-9.78,7.65-9.78,14.11,0,8.36,6.78,15.13,15.13,15.13s15.13-6.78,15.13-15.13c0-6.47-4.07-11.94-9.78-14.11v-30.03c5.71-2.17,9.78-7.65,9.78-14.11Z"
        />
        <path
          fill={color2}
          d="M136.78,216.62c0-11.84-9.6-21.44-21.44-21.44h-2.49v-18.61h-9.31l-30.91,30.74v9.31h16.96v22.77c0,26.09,16.45,41.38,47.19,41.38v-21.6c-15.95,0-23.93-6.48-23.93-19.78v-22.77h23.93Z"
        />
        <path
          fill={color2}
          d="M193.61,192.69c-26.67,0-46.37,18.77-46.37,44.2s19.86,43.87,46.37,43.87,46.51-17.95,46.51-43.87-19.54-44.2-46.51-44.2ZM193.61,259.16c-14.4,0-25.16-9.31-25.16-22.27s10.61-22.77,25.16-22.77,25.31,9.48,25.31,22.77-10.16,22.27-25.31,22.27Z"
        />
        <path
          fill={color2}
          d="M328.87,233.9c0,14.79-10.31,25.26-24.93,25.26s-25.26-10.31-25.26-25.26v-38.72h-23.27v38.72c0,27.59,17.45,46.86,42.38,46.86,12.96,0,23.93-6.15,31.24-16.28l2.16,13.79h20.94v-83.09h-23.26v38.72Z"
        />
        <path
          fill={color2}
          d="M392.88,216.78l-2.49-21.6h-20.77v83.09c12.85,0,23.27-10.42,23.27-23.27v-.33c0-23.27,14.45-37.39,39.22-37.39v-24.59c-17.79,0-31.57,9.3-39.22,24.09Z"
        />
        <path
          fill={color2}
          d="M579.66,218.94c0-5.48,6.64-9.47,15.78-9.47,10.97,0,16.96,4.65,17.95,11.3h23.27c-1.33-17.28-16.12-28.08-41.04-28.08s-39.22,11.8-39.22,26.42c0,33.4,58.16,17.28,58.16,35.56,0,5.32-7.15,9.47-16.62,9.47-11.8,0-19.1-4.32-20.44-11.63h-23.27c1.83,17.28,18.78,28.25,43.71,28.25s40.05-11.13,40.05-26.09c0-35.9-58.32-17.95-58.32-35.73Z"
        />
        <path
          fill={color2}
          d="M777.07,192.69c-15.28,0-27.75,9.14-34.24,23.6-6.65-14.63-19.77-23.6-36.22-23.6-12.3,0-22.77,5.98-29.75,15.62l-2.16-13.13h-20.77v59.82c0,12.85,10.42,23.27,23.27,23.27v-38.72c0-14.96,9.47-25.43,23.43-25.43s23.76,10.47,23.76,25.43v38.72h23.27v-38.72c0-14.96,9.47-25.43,23.43-25.43s23.76,10.47,23.76,25.43v38.72h23.27v-38.72c0-27.75-17.11-46.86-41.04-46.86Z"
        />
        <path
          fill={color2}
          d="M880.18,192.69c-26.67,0-46.37,18.77-46.37,44.2s19.86,43.87,46.37,43.87,46.51-17.95,46.51-43.87-19.54-44.2-46.51-44.2ZM880.18,259.16c-14.4,0-25.16-9.31-25.16-22.27s10.61-22.77,25.16-22.77,25.31,9.48,25.31,22.77-10.16,22.27-25.31,22.27Z"
        />
      </svg>
    </Box>
  );
};
